import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { formatPostDate } from "../lib/formatPostDate";
import SmallText from "../styles/SmallText";

const PostsList = () => {
  const {
    allMarkdownRemark: { edges: posts }
  } = useStaticQuery(query);

  return (
    <PostsListWrapper>
      {posts.map(({ node }) => {
        const { title, path, date, tagline } = node.frontmatter;
        return (
          <Post key={path}>
            <Link to={path} key={path}>
              {title}
            </Link>
            <br />
            <SmallText>{formatPostDate(date)}</SmallText>
            <p>{tagline}</p>
          </Post>
        );
      })}
    </PostsListWrapper>
  );
};

export const query = graphql`
  query blogListQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            tagline
          }
        }
      }
    }
  }
`;

const PostsListWrapper = styled.ul`
  padding: 0;
  list-style: none;
`;

const Post = styled.li`
  a {
    font-weight: 700;
    text-decoration: none;
    font-size: 3rem;
    color: ${({ theme }) => theme.primary};
  }
`;

export default PostsList;
